import { Controller } from "@hotwired/stimulus"
import { DotLottie } from "@lottiefiles/dotlottie-web"
// Connects to data-controller="lottie"
export default class extends Controller {
  static values = {
    src: String,
    loop: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: true
    }
  }

  connect() {
    new DotLottie({
      canvas: this.element,
      src: this.srcValue,
      loop: this.loopValue,
      autoplay: this.autoplayValue
    })
  }
}
